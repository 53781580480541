import { React, useContext, useEffect, useState, useCallback } from "react"
import { Geolocation } from "@capacitor/geolocation"
import { useNavigate } from "react-router-dom"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import FormLabel from "@mui/material/FormLabel"
import { useForm, Controller } from "react-hook-form"
import { Preferences } from "@capacitor/preferences"
import { CommonContext } from "../contexts/CommonContext"
import { motion } from "framer-motion"
import {
  addNewAddress,
  getAllUserAddress,
  getBranchInfo,
  getDeliveryCharge,
  getNearestStoreDetails,
  getStockAvailability,
  getUserDeliveryData,
  logAction,
} from "../services/api"
import { getFirebaseError } from "../services/error-codes"
import { AuthContext } from "../contexts/AuthContext"
import ComponentLoader from "../components/ComponentLoader"
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Autocomplete,
} from "@react-google-maps/api"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { Capacitor } from "@capacitor/core"
import NavHeader from "../components/NavHeader"
import { useLocation } from "react-router-dom"
import { BorderBottom } from "@mui/icons-material"
import AddIcon from "../assets/add-icon-delivery.png"
import DeliveryClose from "../assets/delivery-close.png"
import TimeSlot from "../assets/timeslot.png"
const styles = {
  titleCont: {
    fontSize: "25px",
    marginBottom: "10px",
    fontFamily: "Foregen",
    color: "#404040",
    textDecoration: "underline",
    textDecorationColor: "#a4243d",
  },
}

const placesLibrary = ["places"]

function Delivery() {
  const containerStyle = {
    width: "80vw",
    maxWidth: "560px",
    height: "400px",
  }

  const navigate = useNavigate()

  const [showNewAddress, setShowNewAddress] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm()
  const { showLoader, hideLoader, showAlert, showSnackbar, cartData, isDesktop } =
    useContext(CommonContext)
  const { getUserId, logout } = useContext(AuthContext)
  const [newAddressDetail, setNewAddressDetail] = useState({})
  const [deliveryAddress, setDeliveryAddress] = useState(null)
  const [paymentMode, setPaymentMode] = useState("online")
  const { getCartData } = useContext(CommonContext)
  const [loading, setLoading] = useState(true)
  const [address, setAddress] = useState([])
  const [latLong, setLatLong] = useState({})
  const location = useLocation()
  const [searchResult, setSearchResult] = useState("Result: none")

  const [selectedAddrIndex, setSelectedAddrIndex] = useState(null)

  const [delSlot, setDelSlot] = useState("")
  const [delType, setDelType] = useState("")
  const [delDate, setDelDate] = useState("Today")
  const [storeDetails, setStoreDetails] = useState("")
  const [map, setMap] = useState(null)
  const [enableSelfPickup, setEnableSelfPickup] = useState(true)

  const [allDeliverySlots, setAllDeliverySlots] = useState([])
  const [filteredSlots, setFilteredSlots] = useState([])
  const [deliveryCharge, setDeliveryCharge] = useState(0)
  const [hasCoupon, setHasCoupon] = useState(false)
  const [stockMap, setStockMap] = useState({})

  const onDelDateChange = (event, newDate) => {
    setDelSlot(null)
    setDelDate(newDate)
    if (!selectedAddrIndex) return
    showLoader()
    fetchBranchInfo(newDate, storeDetails.branchId)
  }

  const changeDeliverySlot = (newSlot) => {
    setDelSlot(newSlot)
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries: placesLibrary,
  })

  const fetchDeliveryCharge = async () => {
    setDeliveryCharge(await getDeliveryCharge())
    const couponData = await Preferences.get({ key: "couponData" })
    if (couponData.value) {
      if (
        JSON.parse(couponData.value).couponCode == "HEALTHYEATS" ||
        JSON.parse(couponData.value).couponCode == "HEALTHYEGGS"
      ) {
        setHasCoupon(true)
      }
    }
  }

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({
      lat: 17.3616,
      lng: 78.4747,
    })
    map.fitBounds(bounds)
    map.setCenter(bounds.getCenter())
    setMap(map)
  }, [])

  const mapPositionChanged = (e) => {
    setLatLong({
      lat: e.lat(),
      lng: e.lng(),
    })
  }

  function onAutoCompleteLoad(autocomplete) {
    setSearchResult(autocomplete)
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace()
      window["currentLocation"] = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      setLatLong(window["currentLocation"])
    } else {
      showAlert("Please enter address for map", "error")
    }
  }

  useEffect(() => {
    logAction("PAGE_VIEW", "delivery")
    getUserAddress()
    fetchDeliveryCharge()
    window["currentLocation"] = { lat: 17.38714, lng: 78.491684 }
  }, [])

  const printCurrentPosition = async () => {
    await Geolocation.getCurrentPosition()
      .then((resp) => {
        // console.log("current position is : ", resp.coords)
        setLatLong({
          lat: resp.coords.latitude,
          lng: resp.coords.longitude,
        })
        window["currentLocation"] = {
          lat: resp.coords.latitude,
          lng: resp.coords.longitude,
        }
      })
      .catch((err) => {
        requestForLocPermission()
      })
  }

  const requestForLocPermission = async () => {
    await Geolocation.requestPermissions("location")
      .then((resp) => {
        if (resp.location == "granted") printCurrentPosition()
        else
          showAlert(
            "Location permission is disabled. Please enable location to continue"
          )
      })
      .catch((err) => {
        showAlert(
          "Location permission is disabled. Please enable location to continue"
        )
      })
  }

  const getUserAddress = async () => {
    getUserDeliveryData(await getUserId())
      .then((response) => {
        setAddress(response.addresses)
        // setAllDeliverySlots(response.timeSlots)
        // setFilteredSlots(response.timeSlots.filter((slot) => slot.delTime == delDate))
        setLoading(false)
      })
      .catch((error) => {
        // setLoading(false)
        logout()
        showAlert(
          "Unexpected error occured. For any issues/enquiries please contact customer care"
        )
      })
  }

  const onFormSubmit = async (data) => {
    data.houseDetails = data.houseDetails.replace("'", "")
    data.landmark = data.landmark.replace("'", "")
    data.streetDetails = data.streetDetails.replace("'", "")

    data.userId = await getUserId()
    data.timeStamp = Date.now()
    data.latLong = latLong

    if (!latLong.lat || !latLong.lng) {
      showAlert("Please select your delivery location on map to add address.")
      return
    }

    showLoader()
    addNewAddress(data, false)
      .then((response) => {
        getUserAddress()
        hideLoader()
      })
      .catch((error) => {
        hideLoader()
        showAlert(getFirebaseError(error))
      })

    reset()
    setShowNewAddress(false)
  }

  const closeAddNewAddrForm = async () => {
    setShowNewAddress(false)
    reset()
  }

  const changeDeliveryType = (e) => {
    setDelType(e.target.value)
    setPaymentMode("online")
  }
  const PaymentOption = ({ value, label, isSelected, extraText }) => (
    <FormControlLabel
      value={value}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        fontFamily:'RobotSlab',
        borderRadius: "7px",
        width: "100%",
        flex: 1, // Equal width for buttons
        // padding: "5px",
        background: isSelected ? "#a4243d" : "none",
        color: isSelected ? "#FFF5E8" : "#2c2c2c",
        border: isSelected ? "none" : "1px solid #2c2c2c",
        transition: "background 0.3s",
        marginRight: "0px !important",
      }}
      control={
        <Radio
          sx={{
            color: "#2c2c2c",
            margin: "2px",
            "&.Mui-checked": {
              color: "#FFF5E8",
            },
          }}
        />
      }
      label={
        <Box
          sx={{
            fontSize: "12px",
            width: "100%",
            fontWeight: "500",
            // padding: "2px",
            marginRight: "15px !important",
          }}
        >
          {label}
          {extraText && (
            <Box
              sx={{
                fontSize: "8px",
                color: isSelected ? "#FFF5E8" : "#2c2c2c",
              }}
            >
              {extraText}
            </Box>
          )}
        </Box>
      }
    />
  )

  const confirmOrder = async () => {
    if (!deliveryAddress) {
      showSnackbar("Please select an address to continue", "error")
      return
    }

    if (!delSlot) {
      showSnackbar("Please select a delivery slot", "error")
      return
    }

    if (!delDate) {
      showSnackbar("Please select a delivery date", "error")
      return
    }

    if (!paymentMode) {
      showSnackbar("Please select a payment mode", "error")
      return
    }

    const cartData = await getCartData()
    let isOOS = false
    //Checking stock availability
    Object.keys(cartData).forEach((item) => {
      if (stockMap[item] == "off") {
        const alertMsg = `
 We're sorry, but the item ${cartData[item].name} just went out of stock. We will notify you as soon as it is available again. Thank you for your understanding!`
        showAlert(alertMsg)
        isOOS = true
      }
    })

    if (isOOS) return

    const summaryProps = {
      addressDetails: deliveryAddress,
      paymentMode: paymentMode,
      delSlotId: delSlot,
      delDate: delDate,
      delSlotTime: filteredSlots.filter(
        (slot) => slot.id == delSlot && slot.delTime == delDate
      )[0].time,
      delType: delType,
      storeDetails: storeDetails,
      itemDetails: cartData,
      instructions: location?.state?.instructions,
    }
    navigate(`/orderSummary`, { state: summaryProps, replace: true })
  }

  const handleAddressChange = async (event) => {
    // event.preventDefault();

    showLoader()

    if (!address[event.target.value].storeDetails) {
      const resp = await getNearestStoreDetails(address[event.target.value])

      if (!resp.branchId || !resp.locCode) {
        showAlert(
          <>
            <b>
              We regret to inform you that delivery is not available for the
              selected address.
            </b>
            <br />
            <br /> Please note that we deliver within a maximum radius of 8km
            and the selected address lies beyond our delivery range.
          </>
        )
        hideLoader()
        return
      }

      modifyPickupStatus(resp)
      setStoreDetails(resp)

      address[event.target.value].storeDetails = {}
      address[event.target.value].storeDetails.branchId = resp.branchId
      await fetchBranchInfo("Today", resp.branchId)
    } else {
      modifyPickupStatus(address[event.target.value].storeDetails)
      setStoreDetails(address[event.target.value].storeDetails)

      await fetchBranchInfo(
        "Today",
        address[event.target.value].storeDetails.branchId
      )
    }

    getStockAvailability({
      storeId: address[event.target.value].storeDetails.branchId,
    }).then((response) => {
      setStockMap(response)
      hideLoader()
    })

    setSelectedAddrIndex(event.target.value)
    setDeliveryAddress(address[event.target.value])
  }

  const modifyPickupStatus = (storeDetails) => {
    if (storeDetails.selfPickup) setEnableSelfPickup(true)
    else setEnableSelfPickup(false)
    setDelType("")
  }

  const fetchBranchInfo = async (deliveryDate, branchId) => {
    const branchInfo = await getBranchInfo({
      userId: await getUserId(),
      branchId: branchId,
      deliveryDate: deliveryDate,
      timeStamp: Date.now(),
      cartData: Object.keys(await getCartData()),
    })
    setFilteredSlots(branchInfo.slots)
    setDelSlot("")
    setDelDate(deliveryDate)
    hideLoader()
  }

  const handlePaymentChange = async (event) => {
    setPaymentMode(event.target.value)
  }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Box sx={{ maxWidth: "700px", marginTop: isDesktop ? "14vh" : "7vh", marginLeft: isDesktop ? '50px' : "" }}>
        {loading ? (
          <ComponentLoader />
        ) : (
          <Box>
            <Box sx={{ padding:"0px 20px" }}>
              <NavHeader />
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box style={styles.titleCont}>Delivery Details</Box>
                  <Box>
                    <img
                      src={DeliveryClose}
                      onClick={() => navigate(-2)}
                      alt="Close"
                    />
                  </Box>
                </Box>
                <Box sx={{ fontFamily: "RobotoSlabBold" }}>Address</Box>

                <RadioGroup
                  onChange={handleAddressChange}
                  value={selectedAddrIndex}
                >
                  {address.map((address, index) => {
                    return (
                      <Box key={index}>
                        <Box
                          sx={{
                            padding: "10px",
                            margin: "5px 0",
                            background: "rgba(164, 36, 61, 0.1)",
                            borderRadius: "5px",
                          }}
                        >
                          <FormControlLabel
                            value={index}
                            control={
                              <Radio sx={{ color: "#a4243d !important" }} />
                            }
                            label={
                              <Box
                                sx={{
                                  fontSize: "14px",
                                  color: "#404040",
                                  fontFamily: "RobotoSlab",
                                  width: "250px",
                                }}
                              >
                                <Box sx={{ fontFamily: "RobotoSlabBold" }}>
                                  {address.userName} +91 98765 43210
                                </Box>
                                <Box sx={{ fontSize: "12px" }}>
                                  {address.houseDetails},{" "}
                                  {address.streetDetails}
                                </Box>
                                <Box sx={{ fontSize: "12px" }}>
                                  {address.landmark}
                                </Box>
                                <Box sx={{ fontSize: "12px" }}>
                                  {address.pincode}
                                </Box>
                              </Box>
                            }
                          />
                        </Box>
                      </Box>
                    )
                  })}
                </RadioGroup>

                {showNewAddress ? (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ mt: 2, mb: 2 }}>Add New address</Box>

                    <Box
                      sx={{
                        padding: "10px",
                        background: "#FFF5E8",
                        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.15)",
                        borderRadius: "5px",
                      }}
                    >
                      <form onSubmit={handleSubmit(onFormSubmit)}>
                        <Autocomplete
                          onLoad={onAutoCompleteLoad}
                          onPlaceChanged={onPlaceChanged}
                        >
                          <input
                            type="text"
                            placeholder="Search for your location"
                            style={{
                              boxSizing: `border-box`,
                              border: `1px solid transparent`,
                              width: `100%`,
                              height: `40px`,
                              padding: `0 12px`,
                              borderRadius: `3px`,
                              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                              fontSize: `14px`,
                              marginBottom: "20px",
                              outline: `none`,
                              textOverflow: `ellipses`,
                            }}
                          />
                        </Autocomplete>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => printCurrentPosition()}
                        >
                          Use Current Location
                        </Button>
                        <Box mb={3} mt={2}>
                          {isLoaded ? (
                            <GoogleMap
                              mapContainerStyle={containerStyle}
                              onLoad={onLoad}
                              zoom={10}
                              center={window["currentLocation"]}
                              options={{
                                mapTypeControl: false,
                                fullscreenControl: false,
                                minZoom: 15,
                              }}
                              onUnmount={onUnmount}
                            >
                              <Marker
                                position={window["currentLocation"]}
                                draggable={true}
                                onDrag={(e) => mapPositionChanged(e.latLng)}
                              />
                            </GoogleMap>
                          ) : (
                            <></>
                          )}
                        </Box>

                        <Box mb={3}>
                          <TextField
                            label="Full Name"
                            variant="outlined"
                            fullWidth
                            defaultValue={newAddressDetail.userName}
                            name="userName"
                            {...register("userName", {
                              required: "Required field",
                            })}
                            error={Boolean(errors?.userName)}
                            helperText={errors?.userName?.message}
                          />
                        </Box>

                        <Box mb={3}>
                          <TextField
                            label="Flat, House no, Apartment"
                            variant="outlined"
                            fullWidth
                            defaultValue={newAddressDetail.houseDetails}
                            name="houseDetails"
                            {...register("houseDetails", {
                              required: "Required field",
                            })}
                            error={Boolean(errors?.houseDetails)}
                            helperText={errors?.houseDetails?.message}
                          />
                        </Box>

                        <Box mb={3}>
                          <TextField
                            label="Area, Street, City"
                            variant="outlined"
                            fullWidth
                            defaultValue={newAddressDetail.streetDetails}
                            name="streetDetails"
                            {...register("streetDetails", {
                              required: "Required field",
                            })}
                            error={Boolean(errors?.streetDetails)}
                            helperText={errors?.streetDetails?.message}
                          />
                        </Box>

                        <Box mb={3}>
                          <TextField
                            placeholder="Enter Landmark"
                            label="Landmark"
                            variant="outlined"
                            fullWidth
                            defaultValue={newAddressDetail.landmark}
                            name="landmark"
                            {...register("landmark", {
                              required: "Required field",
                            })}
                            error={Boolean(errors?.landmark)}
                            helperText={errors?.landmark?.message}
                          />
                        </Box>

                        <Box mb={3}>
                          <TextField
                            label="Pincode"
                            ariant="outlined"
                            fullWidth
                            defaultValue={newAddressDetail.pincode}
                            name="pincode"
                            {...register("pincode", {
                              required: "Required field",
                              pattern: {
                                value: /^[1-9][0-9]{5}$/,
                                message:
                                  "Invalid pincode. Pincode must be 6 digits",
                              },
                            })}
                            error={Boolean(errors?.pincode)}
                            helperText={errors?.pincode?.message}
                          />
                        </Box>

                        <Button
                          onClick={() => closeAddNewAddrForm()}
                          sx={{ mr: 2 }}
                          variant="outlined"
                        >
                          Cancel
                        </Button>
                        <Button type="submit" variant="contained">
                          Save
                        </Button>
                      </form>
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ marginTop: "10px" }}>
                    <Button
                      onClick={() => setShowNewAddress(true)}
                      variant="contained"
                      size="small"
                      startIcon={
                        <img
                          src={AddIcon}
                          alt="Add Address"
                          style={{ width: "15px", height: "15px" }}
                        />
                      }
                    >
                      Add New address
                    </Button>
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  mb: 1,
                  mt: 4,
                  fontSize: "20px",
                  fontFamily: "Foregen",
                  color: "#a4243d",
                }}
              ></Box>
              <Box
                sx={{
                  padding: "0px",
                  background: "#FFF5E8",
                  borderRadius: "5px",
                }}
              >
                <Select
                  placeholder="Delivery Type"
                  size="small"
                  displayEmpty
                  value={delType}
                  style={{
                    background: "#fff0d9",
                    fontWeight: "500",
                    width: "80%",
                    fontSize: "14px",
                    fontFamily: "RobotoSlab",
                  }}
                  onChange={(e) => changeDeliveryType(e)}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        bgcolor: "#fff0d9",
                        color: "black",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            bgcolor: "#a4243d",
                            color: "#fff0d9",
                          },
                        },
                      },
                    },
                  }}
                >
                  <MenuItem disabled value="">
                    Select Delivery Type
                  </MenuItem>
                  <MenuItem
                    key="delivery"
                    value="delivery"
                    sx={{
                      justifyContent: "space-between",
                      fontFamily:'RobotoSlab',
                      fontSize: "14px",
                    }}
                  >
                    <span>Home delivery</span> <span>₹{deliveryCharge}</span>
                  </MenuItem>
                  {/* <MenuItem
 key="express_delivery"
 value="Express delivery"
 sx={{
 justifyContent: "space-between",
 }}
 >
 <span>Express delivery <span style={{fontSize:'9px'}}>{'(90 mins)'}</span></span> <span>₹{deliveryCharge + 55}</span>
 </MenuItem> */}
                  {enableSelfPickup ? (
                    <MenuItem
                      key="self_pickup"
                      value="self_pickup"
                      sx={{
                        justifyContent: "space-between",
                        fontFamily:'RobotoSlab',
                        fontSize: "14px",
                      }}
                    >
                      <span>Self pickup - at store</span>{" "}
                      <span>₹{deliveryCharge - (hasCoupon ? 30 : 39)}</span>
                    </MenuItem>
                  ) : null}
                </Select>
              </Box>
              <Box
                sx={{
                  fontSize: "10px",
                  fontWeight: "400",
                  marginBottom: "5px",
                  marginTop: "10px",
                  fontFamily:'RobotoSlab'
                }}
              >
                Your order will be delivered from{" "}
                <span style={{ color: "#a4243d" }}>
                  {storeDetails.storeName} store
                </span>
              </Box>

              <Box
                sx={{
                  mb: 1,
                  fontSize: "14px",
                  color: "#2C2C2C",
                  mt: 4,
                  fontFamily: "RobotoSlabBold",
                }}
              >
                Select Delivery Slot
              </Box>

              <Box
                mb={3}
                sx={{
                  padding: "15px 10px",
                  background: "#a4243d",
                  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.15)",
                  borderRadius: "5px",
                  fontFamily:'RobotoSlab'
                }}
              >
                <Box sx={{ display: "flex", mb: 2 }}>
                  <ToggleButtonGroup
                    color="primary"
                    value={delDate}
                    exclusive
                    onChange={onDelDateChange}
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <ToggleButton
                      sx={{
                        textTransform: "none",
                        padding: "0px 15px 0px 15px",
                        fontSize:'13px',
                        color:
                          delDate == "Today"
                            ? "#a4243d !important"
                            : "rgba(255, 246, 233, 1) !important",
                        background:
                          delDate == "Today"
                            ? "rgba(255, 246, 233, 1) !important"
                            : "#a4243d !important",
                        border:
                          delDate == "Today"
                            ? "none !important"
                            : "1px solid #FFF5E8 !important",
                        borderTopRightRadius: "5px !important",
                        borderBottomRightRadius: "5px !important",
                        fontFamily:'RobotoSlab'
                      }}
                      value="Today"
                    >
                      Today
                    </ToggleButton>
                    <ToggleButton
                      sx={{
                        textTransform: "none",
                        padding: "3px 15px 3px 15px",
                        fontSize:'13px',
                        color:
                          delDate == "Tomorrow"
                            ? "#a4243d !important"
                            : "#FFF5E8 !important",
                        background:
                          delDate == "Tomorrow"
                            ? "#FFF5E8 !important"
                            : "#a4243d !important",
                        border:
                          delDate == "Tomorrow"
                            ? "none !important"
                            : "1px solid #FFF5E8 !important",
                        borderTopLeftRadius: "5px !important",
                        borderBottomLeftRadius: "5px !important",
                        fontFamily:'RobotoSlab'
                      }}
                      value="Tomorrow"
                    >
                      Tomorrow
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>

                {/* {
 delDate == 'Today' ? 
 <Box sx={{background:'white', padding:'10px'}}>
 <Box mb={2}>
 Due to an exceptionally high demand, we've temporarily run out of stock.
 </Box>

 <Box sx={{color:'#a4243d', fontSize:'20px'}}>
 Please select a slot for tomorrow to continue order.
 </Box>
 

 </Box> : */}
                <Select
                  labelId="demo-select-small"
                  value={delSlot}
                  size="small"
                  displayEmpty
                  style={{
                    color: "#FFF0D9",
                    width: "80%",
                    border: "1px solid #fff",
                  }}
                  onChange={(e) => changeDeliverySlot(e.target.value)}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        bgcolor: "#FFF0D9",
                        fontFamily:'RobotoSlab',
                        color: "#2C2C2C", // Default text color
                      },
                    },
                  }}
                >
                  {/* Placeholder item with icon */}
                  <MenuItem value="" disabled>
                    <Box display="flex" alignItems="center" gap={1}>
                      <img src={TimeSlot}></img>
                      <Box sx={{ fontFamily:'RobotoSlab', fontSize:'13px'}}>Choose Time Slot</Box>
                    </Box>
                  </MenuItem>

                  {/* Dynamic options */}
                  {selectedAddrIndex ? (
                    filteredSlots.map((item) => (
                      <MenuItem
                        key={item.id}
                        disabled={item.isDisabled}
                        value={item.id}
                        sx={{
                          "&.Mui-disabled": { color: "gray" }, // Disabled item styling
                          "&:hover": { bgcolor: "#a4243d", color: "#FFF0D9" }, // Hover styling
                        }}
                      >
                        {item.time} {item.isDisabled ? "(Not Available)" : ""}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>
                      Please select an address to add slot
                    </MenuItem>
                  )}
                </Select>

                {/* } */}

                {/* <Select
 labelId="demo-select-small"
 placeholder="Delivery Time"
 fullWidth
 value={delSlot}
 onChange={(e) => changeDeliverySlot(e.target.value)}>
 {
 timeSlots.map((item) => {
 return (
 <MenuItem key={item.id} disabled={filteredSlots.indexOf(item.id) == -1} value={item.id}>
 {item.time} {filteredSlots.indexOf(item.id) == -1 ? '(Not Available)' : null}
 </MenuItem>
 )
 })
 }
 </Select> */}
              </Box>

              <Box
                sx={{
                  fontSize: "14px",
                  color: "#000",
                  margin: "5px",
                  fontFamily:"RobotoSlabBold"
                }}
              >
                Payment Mode
              </Box>
              <Box
                sx={{
                  mb: 3,
                  ml: 2,

                  background: "#FFF5E8",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormControl
                  onChange={handlePaymentChange}
                  style={{ width: "max-content" }}
                >
                  <RadioGroup defaultValue="online" sx={{ color: "#FFF5E8" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 3,
                        background: "#FFF5E8",
                        borderRadius: "5px",
                        fontFamily:'RobotoSlab'
                      }}
                    >
                      {/* Online Payment Option */}
                      <PaymentOption
                        value="online"
                        label="Online Payment"
                        isSelected={paymentMode === "online"}
                      />

                      {/* Cash On Delivery Option */}
                      {!(hasCoupon || delType === "self_pickup") && (
                        <PaymentOption
                          value="cod"
                          label="Cash On Delivery"
                          isSelected={paymentMode === "cod"}
                        />
                      )}
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            <Box
              sx={{
                backgroundColor: "#a4243d",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                // maxHeight: "80px",
                position: "relative",
                padding: "0px 0px 30px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#a4243d",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  width: "40%",
                  height: "100%",
                  // height: "80%",
                }}
              >
                <Button
                  onClick={() => confirmOrder()}
                  variant="outlined"
                  fullWidth
                  style={{
                    background: "#fff5e8",
                    display: "block",
                    justifyContent: "center",
                    position: "relative",
                    bottom: "0",
                    marginTop: "25px",
                    height: "30px",
                    paddingBottom:'10px',
                    fontFamily:'RobotoSlabBold',
                    minWidth:'200px'
                  }}
                >
                  Proceed to Checkout
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </motion.div>
  )
}

export default Delivery
