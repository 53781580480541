import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import FacebookIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import DownloadApple from './assets/download-apple.png';
import DownloadGoogle from './assets/download-google.png';
import CccLogo from './assets/ccc-logo.png';
import RaisedByNature from './assets/raised-by-nature.png';
import FooterLogo from './assets/lan-footer.png';
import FooterBg from './assets/footer-bg.png';
import { useContext } from 'react';
import { CommonContext } from './contexts/CommonContext';
import { useNavigate } from 'react-router-dom';
import { color } from 'framer-motion';
import FooterImage from './assets/Group76.png';
import { height, width } from '@mui/system';
const styles = {
  whyccc: {
    color:'#FFF0D9',
    display:'flex',
    padding:'2vw',
    textAlign:'center',
    flexDirection:'column',
    background:'#a4243d', 
    backgroundSize:'cover',
    padding:'80px 0 10px 0px'
  },
  whycccDesk : {
    color:'#000',
    display:'flex',
    padding:'2vw',
    textAlign:'center',
    flexDirection:'column',
    background:`url(${FooterBg})`, 
    backgroundSize:'cover',
    padding:'80px 0 0px 0px',
    height:'550px'
  },
  contentCont: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    display: 'flex',
    position: 'relative'
  },
  footerImage:{
position:"relative",
margin:'10px',
width:'80%',
  },
  footerImageCont:{
    position:"relative",
    margin:'10px',
    width:'30%',
  },
  storeIcon: {
    width: '100px',
    height: '30px',
    borderRadius: '10px',
    cursor: 'pointer',
    padding:"-5px",
  },
  extraImg: {
    maxHeight: '120px',
    marginBottom: '20px',
    maxWidth: '350px'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '10px',
    background:"#FFF0D9",
    borderRadius: "5px",
    padding:"2px 0 2px",
   
    
  },
  icon: {
    fontSize: '1.2rem',
    cursor: 'pointer',
    background:'#a4243d',
    color:"#FFF0D9",
    borderRadius:"45%",
    border: "2px solid #a4243d"
    
  },
  textSection: {
  
    color: '#FFF0D9',
    textAlign: 'center',
    textDecoration: "underline",
    fontFamily:'Foregen'

  },
  title:{
    fontSize:'1rem', fontWeight:'400', marginBottom:'10px', textAlign:'start', marginLeft:'4vw',textDecoration: 'underline',fontFamily:'Foregen'
  }
};

function Footer() {
  const navigate = useNavigate();
  const { isDesktop } = useContext(CommonContext);

  const openUrl = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box style={isDesktop ? styles.whycccDesk : styles.whyccc}>
      <Grid container spacing={2} sx={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between',
    width: '100%', }}>
        
        {/* First Row */}
        <Grid  sm={4} md={6} lg={6}>
        <div style={styles.contentCont}>
          <Box sx={{display:'flex', flexDirection:'column'}}>
            <Box sx={{fontSize:'1rem', fontWeight:'400', marginBottom:'10px', textAlign:'start', marginLeft:'6vw',textDecoration: 'underline',fontFamily:'Foregen'}}>
              Our Products
            </Box>
            <Box sx={{marginLeft:'6vw', fontSize:isDesktop? '15px':'11px',fontWeight:'400',fontFamily:'Roboto',cursor:'pointer'}}>
              <Box sx={{display:'flex', alignItems:'center', marginBottom:'10px'}}
                onClick={() => navigate('products/better-than-broiler-chicken')}>
                Better Than Broiler Chicken
              </Box>
              <Box sx={{display:'flex', alignItems:'center', marginBottom:'10px'}}
                onClick={() => navigate('products/tender-country-chicken')}>
                Tender Country Chicken
              </Box>
              <Box sx={{display:'flex', alignItems:'center', marginBottom:'10px',}}
                onClick={() => navigate('products/classic-country-chicken')}>
                Classic Country Chicken
              </Box>
              <Box sx={{display:'flex', alignItems:'center', marginBottom:'10px'}}
                onClick={() => navigate('/categories/mutton')}>
                 Country Mutton
              </Box>
              <Box sx={{display:'flex', alignItems:'center', marginBottom:'10px'}}
                onClick={() => navigate('products/young-warrior')}>
                Warrior Country Chicken
              </Box>
              <Box sx={{display:'flex', alignItems:'center', marginBottom:'10px'}}
                onClick={() => navigate('categories/pickles')}>
                Non-Veg Pickles
              </Box>
              <Box sx={{display:'flex', alignItems:'center', marginBottom:'10px'}}
                onClick={() => navigate('products/brown-eggs-6pack')}>
                Eggs
              </Box>
              <Box sx={{display:'flex', alignItems:'center', marginBottom:'10px'}}
                onClick={() => navigate('categories/Amma-Chethi-Marinades')}>
                Marinades
              </Box>
            </Box>
          </Box>
        </div>
      </Grid>

      <Grid  sm={4} md={6} lg={6}>
        <div style={styles.contentCont}>
          <Box sx={{display:'flex', flexDirection:'column'}}>
            <Box sx={{fontSize:'1rem', fontWeight:'400', marginBottom:'10px',lineHeight:'22.7px', textAlign:'start', marginLeft:'4vw',fontFamily:'Foregen',textDecoration: 'underline'}}>
            Know Us
            </Box>
            <Box sx={{marginLeft:'4vw' , fontSize:isDesktop? '15px':'11px',fontWeight:'400'}}>
              <Box sx={{display:'flex', alignItems:'center', marginBottom:'10px', cursor:'pointer'}}
                onClick={() => navigate('/aboutUs')}>
                Our Story
              </Box>
              <Box sx={{display:'flex', alignItems:'center', marginBottom:'10px', cursor:'pointer'}}
                onClick={() => navigate('/contactUs')}>
                Contact Us
              </Box>
              <Box sx={{display:'flex', alignItems:'center', marginBottom:'10px', cursor:'pointer'}}
                onClick={() => navigate('/termsAndConditions')}>
                Terms And Conditions
              </Box>
              <Box sx={{display:'flex', alignItems:'center', marginBottom:'10px', cursor:'pointer'}}
                onClick={() => navigate('/privacyPolicy')}>
                Privacy Policy
              </Box>
              <Box sx={{display:'flex', alignItems:'center', marginBottom:'10px', cursor:'pointer'}}
                onClick={() => navigate('/refundPolicy')}>
                Cancellation / Refund Policy
              </Box>
            </Box>
          </Box>
        </div>
      </Grid>

        {/* Second Row */}
        
        <Grid xs={6} sm={6} md={6}>
          <Box sx={styles.textSection}>
            <Box sx={{ fontSize: '1rem', fontWeight: '400', marginBottom: '10px', }}>
              Download Our App
            </Box>
            <Box sx={{ display: 'flex',marginLeft:'6vw', justifyContent: 'center',  }}>
              <img src={DownloadGoogle} alt="Download on Google Play" style={styles.storeIcon} />
              <img src={DownloadApple} alt="Download on Apple Store" style={styles.storeIcon} />
            </Box>
          </Box>
        </Grid>

        <Grid xs={5} sm={6} md={2}>
          <Box sx={styles.textSection}>
            <Box sx={{ fontSize: '1rem', fontWeight: '400', marginBottom: '10px' }}>
              Connect with Us
            </Box>
            <Box sx={styles.iconContainer}>
            
              <FacebookIcon style={styles.icon} onClick={() => openUrl('https://facebook.com')} />
              <InstagramIcon style={styles.icon} onClick={() => openUrl('https://instagram.com')} />
              <WhatsAppIcon style={styles.icon} onClick={() => openUrl('https://whatsapp.com')} />
              <LinkedInIcon style={styles.icon} onClick={() => openUrl('https://linkedin.com')} />
            </Box>
          </Box>
        </Grid>
        <Grid xs={1} sm={1} md={1}></Grid>
        <Grid sx={{width :'100%'}}>
          <Box >
        <img src={FooterImage} alt="Footer Image" style={isDesktop ? styles.footerImageCont: styles.footerImage } />
           
          </Box>
        </Grid>
      </Grid>
      
    </Box>
  );
}

export default Footer;
