import React, { useContext, useEffect, useState } from "react"
import Box from "@mui/material/Box"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { CommonContext } from "../contexts/CommonContext"
import { useLocation } from "react-router-dom"
import { getImgMap, getDeliveryCharge } from "../services/api"
import Minus from "../assets/minus.png"
import Plus from "../assets/plus.png"
const styles = {
  cartCont: {
    display: "flex",
    padding: "10px",
  },
  shadowBox: {
    background: "white",
    boxShadow: "0px 0px 10px 0px #eaeaea",
  },
  cartImg: {
    width: "200px",
    height: "125px",
    borderRadius: "17px",
  },
  cartImgMob: {
    width: "120px",
    height: "120px",
    borderRadius: "17px",
    marginRight:'10px'
  },
}

function ItemsSummary(props) {
  const { updateCart, isDesktop } = useContext(CommonContext)
  const location = useLocation()
  const [itemDetails, setItemDetails] = useState({})
  const [totalPrice, setTotalPrice] = useState()
  const orderPageFlag = location.pathname == "/orderSummary" ? true : false
  const cartPageFlag = location.pathname == "/cart" ? true : false
  const textStyle = {
    fontSize: "12px",
    fontWeight: 500,
    margin: "0 10px 0 10px ",
    fontFamily: "RobotoSlab",
  }

  const TotalPrice = (itemDetails) => {
    return Object.values(itemDetails).reduce((total, item) => {
      const basePrice = item?.price || 0
      const flavourExtra =
        item?.extras?.flavourType === "smoketurmeric" ? 30 : 0
      const skinExtra = item?.extras?.skinType === "skinless" ? 100 : 0

      return total + basePrice + flavourExtra + skinExtra
    }, 0)
  }
  useEffect(() => {
    setTotalPrice(TotalPrice(props.itemDetails))
    setItemDetails(props.itemDetails)
    fetchDeliveryCharge()
  }, [])
  const [deliveryCharges, setDeliveryCharge] = useState()
  const fetchDeliveryCharge = async () => {
    setDeliveryCharge(await getDeliveryCharge())
  }

  return (
    <Box sx={{ background: "#FFF7E9", borderRadius: "5px" }}>
      {Object.keys(itemDetails).map((item, index) => {
        return (
          <Box key={index}>
            {item == "totalCount" ||
            item == "totalAmount" ||
            item == "totalDiscount" ||
            item == "bogoDiscount" ? null : (
              <Box
                key={index}
                style={styles.cartCont}
                sx={{ flexDirection: "row" }}
              >
                {cartPageFlag && (
                  <Box>
                    <img
                      src={props.itemDetails[item]?.imgUrls[0]}
                      style={isDesktop ? styles.cartImg : styles.cartImgMob}
                    />
                  </Box>
                )}

                <Box sx={{ width: "100%", padding: "0px" }}>
                  <Box
                    sx={{
                      fontSize: "15px",
                      fontWeight: "600",
                      display: "flex",
                      marginBottom: "1px",

                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "24px",
                        fontWeight: "400",
                        marginLeft: "3px",
                        lineHeight: "25px",
                        fontFamily: "Foregen",
                        color: orderPageFlag ? "#A4243D" : "#000",
                      }}
                    >
                      {props.itemDetails[item].name}
                    </Box>

                    {orderPageFlag && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            padding: "2px",
                            fontSize: "15px",
                            fontWeight: "500",
                            marginLeft: "10px",
                            lineHeight: "18px",
                            fontFamily: "RobotoSlab",
                            marginRight: "2px",
                          }}
                        >
                          QTY
                        </Box>
                        <Box
                          sx={{
                            width: "28px",
                            height: "28px",
                            backgroundColor: "#A4243D",
                            color: "#FFF0D9",
                            display: "flex",
                            alignItems: "center",
                                 fontFamily: "RobotoSlab",
                            justifyContent: "center",
                            fontSize: "14px",
                            fontWeight: 500,
                            borderRadius: "4px",
                          }}
                        >
                          {props.itemDetails[item].count < 10
                            ? "0" + props.itemDetails[item].count
                            : props.itemDetails[item].count}
                        </Box>
                      </Box>
                    )}
                    {/* {isDesktop && <Box sx={{display:'flex', width:'50%',margin:'5px', alignItems:'center', justifyContent:'space-between', color:'#a4243d'}}>
                      
                      <Box sx={{display:'flex', alignItems:'center',justifyContent: 'space-between'}}>
                        <Box sx={{ alignItems:'center',fontSize:'16px',background:'white', 
                                  boxShadow:'0px 0px 4px 0px rgba(0, 0, 0, 0.10)', padding:'4px', border : '1px solid #e1dfdf'}}>

                          {
                            (location.pathname == '/cart' && !props.itemDetails[item].disableIncrement) ? 
                            <Box sx={{ padding:'0 7px', cursor:'Itemer', }}
                              onClick={() => updateCart(props.itemDetails[item], false)}>
                              -
                            </Box> : null
                          }
                          
                          <Box sx={{padding:'0px 9px'}}>
                            {props.itemDetails[item].count}
                          </Box>

                          {
                            (location.pathname == '/cart' && !props.itemDetails[item].disableIncrement) ?
                            <Box sx={{padding:'0 7px', cursor:'pointer'}}
                              onClick={() => updateCart(props.itemDetails[item], true)}>
                              +
                            </Box> : null
                          }
                        
                        </Box>
                      </Box>

                      <Box sx={{color:'black'}}>
                        {
                          props.itemDetails[item].enableBogo ? null :
                          <Box sx={{fontSize:'8px', textAlign:'right', opacity:'0.4'}}> <s>₹{(props.itemDetails[item].mrp * props.itemDetails[item].count)}</s></Box>
                        }
                        ₹ {(props.itemDetails[item].price * props.itemDetails[item].count)}                         
                      </Box>
                          
                   
                   </Box>} */}
                  </Box>
                  {orderPageFlag && (
                    <Box
                      sx={{
                        fontSize: "9px",
                        fontWeight: "500",
                        color: "#000",
                        marginLeft: "10px",
                        fontFamily: "RobotoSlab ",
                      }}
                    >
                      Gross wt: 1500gms | Net wt: 900-1000gms
                    </Box>
                  )}
                  {cartPageFlag && (
                    <Box
                      sx={{
                        fontSize: "11px",
                        fontWeight: "500",
                        color: "#000",
                        marginLeft: "10px",
                        fontFamily: "RobotoSlab ",
                      }}
                    >
                      QTY: {props.itemDetails[item].count}
                    </Box>
                  )}

                  {props.itemDetails[item].extras ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        color: "#000",
                        marginLeft: "10px",
                        fontFamily: "RobotoSlab ",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Box
                          sx={{
                            fontSize: "10px",
                            fontWeight: "500",
                            padding: orderPageFlag ? "2px 5px" : null,
                            margin: orderPageFlag ? "5px" : "2px",
                            border: orderPageFlag ? "1px solid #a4243d" : null,
                          }}
                        >
                          {props.itemDetails[item]?.extras?.skinType ==
                          "withskin"
                            ? "With Skin"
                            : "Skinless"}
                        </Box>
                        {cartPageFlag ? (
                          <span style={{ fontSize: "11px" }}>|</span>
                        ) : (
                          ""
                        )}
                        <Box
                          sx={{
                            fontSize: "10px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            padding: orderPageFlag ? "2px 5px" : null,
                            margin: orderPageFlag ? "5px" : "2px",
                            border: orderPageFlag ? "1px solid #a4243d" : null,
                          }}
                        >
                          {props.itemDetails[item]?.extras?.cutType || null} Cut
                        </Box>
                        {cartPageFlag ? (
                          <span style={{ fontSize: "11px" }}>|</span>
                        ) : (
                          ""
                        )}

                        <Box
                          sx={{
                            fontSize: "10px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            padding: orderPageFlag ? "2px 5px" : null,
                            margin: orderPageFlag ? "5px" : "2px",
                            border: orderPageFlag ? "1px solid #a4243d" : null,
                          }}
                        >
                          {props.itemDetails[item]?.extras?.boneType ==
                          "withBones"
                            ? "With Bone"
                            : "Boneless"}
                        </Box>
                      </Box>

                      {props.itemDetails[item]?.extras?.flavourType ==
                      "normal" ? null : (
                        <Box
                          sx={{
                            fontSize: "10px",
                            fontWeight: "500",
                            width: "fit-content",
                            fontFamily: "RobotoSlab",
                            padding: orderPageFlag ? "2px 5px" : null,
                            margin: orderPageFlag ? "5px" : "2px",
                            border: orderPageFlag ? "1px solid #a4243d" : null,
                          }}
                        >
                          Smoked & Turmeric
                        </Box>
                      )}
                    </Box>
                  ) : null}
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    {orderPageFlag && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={textStyle}>Item Price</Box>
                        <Box sx={textStyle}>
                          ₹{props.itemDetails[item].price}
                        </Box>
                      </Box>
                    )}

                    {orderPageFlag &&
                      props.itemDetails[item]?.extras?.flavourType ==
                        "smoketurmeric" && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={textStyle}>Smoked</Box>
                          <Box sx={textStyle}>₹{30}</Box>
                        </Box>
                      )}

                    {orderPageFlag &&
                      props.itemDetails[item]?.extras?.skinType ==
                        "skinless" && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={textStyle}>Skin Less</Box>
                          <Box sx={textStyle}>₹{100}</Box>
                        </Box>
                      )}
                    {/* { orderPageFlag && props.itemDetails[item]?.extras?.boneType != "withBones" && <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={textStyle}>Bone Less</Box>
          <Box sx={textStyle}>₹{298}</Box>
        </Box>} */}
                    {orderPageFlag && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center"                    
                        }}
                      >
                        <Box sx={textStyle}>SubTotal</Box>
                        <Box sx={textStyle}>
                          {" "}
                          ₹{" "}
                          {props.itemDetails[item].price +
                            (props.itemDetails[item]?.extras?.flavourType ===
                            "smoketurmeric"
                              ? 30
                              : 0) +
                            (props.itemDetails[item]?.extras?.skinType ===
                            "skinless"
                              ? 100
                              : 0)}{" "}
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Divider
                    sx={{
                      borderStyle: "dotted",
                      borderColor: "#a4243d",
                      borderWidth: "1px",
                      width: "100%",
                      margin: "10px 0",
                    }}
                  />
                  {props.itemDetails[item] && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        color: "#000",
                        marginLeft: "10px",
                        fontFamily: "RobotoSlab ",
                      }}
                    >
                      {cartPageFlag && (
                        <Box sx={{ fontWeight: "700", fontSize: "24px" }}>
                          ₹{props.itemDetails[item].price}
                        </Box>
                      )}

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          borderRadius: "10%",
                          background: "#A4243D",
                        }}
                      >
                        {cartPageFlag &&
                        !props.itemDetails[item].disableIncrement ? (
                          <Box
                            sx={{
                              cursor: "pointer",
                              color: "#a4243d",

                              borderRadius: "50%",
                            }}
                            onClick={() =>
                              updateCart(props.itemDetails[item], false)
                            }
                          >
                            <img
                              src={Minus}
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "5px",
                                marginTop: "10px",
                              }}
                            ></img>
                          </Box>
                        ) : null}

                        {cartPageFlag && (
                          <Box
                            sx={{
                              fontSize: "15px",
                              background: "#FFF7E9",
                              color: "#A4243D",
                              fontFamily: "RobotoSlab ",
                            }}
                          >
                            <p style={{ margin: "2px 5px 2px 5px" }}>
                              {" "}
                              {props.itemDetails[item].count < 10
                                ? "0" + props.itemDetails[item].count
                                : props.itemDetails[item].count}
                            </p>
                          </Box>
                        )}

                        {cartPageFlag &&
                        !props.itemDetails[item].disableIncrement ? (
                          <Box
                            sx={{
                              cursor: "pointer",

                              borderRadius: "50%",
                            }}
                            onClick={() =>
                              updateCart(props.itemDetails[item], true)
                            }
                          >
                            <img
                              src={Plus}
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "5px",
                                marginTop: "10px",
                              }}
                            ></img>
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  )}

                  {/* {props.itemDetails[item].extras ? <>
                  <Accordion>
                    <AccordionSummary>
                      <Box sx={{display:'flex', justifyContent:'space-between', fontSize:'11px', margin:'5px', width:'100%', color:'#404040'}}>
                        <Box>
                          Meat Weight: 1kg { props.itemDetails[item].enableBogo ? '+ 1kg' : null}
                        </Box>
                        <Box sx={{display:'flex', alignItems:'end'}}>
                          Expand <KeyboardArrowDownIcon sx={{fontSize:'12px'}}/>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{display:'flex', justifyContent:'space-between', fontSize:'11px', marginTop:'5px', opacity:'0.5',
                                borderTop:'1px dotted black', paddingTop:'10px'}}>
                        <Box>
                          Item Price
                        </Box>
                        <Box>
                          ₹{props.itemDetails[item].priceWithoutCust}
                        </Box>
                      </Box>
                                              
                      { 
                        props.itemDetails[item].extras?.flavourType == 'normal' ? null : 
                        <Box sx={{display:'flex', justifyContent:'space-between', fontSize:'11px', marginTop:'5px', opacity:'0.5'}}>
                          <Box>
                            Smoked & Turmeric 
                          </Box>
                          <Box>
                            ₹15
                          </Box>
                        </Box>
                      }

                      { 
                        props.itemDetails[item].extras?.boneType == 'withBones' ? null : 
                        <Box sx={{display:'flex', justifyContent:'space-between', fontSize:'11px', marginTop:'5px', opacity:'0.5'}}>
                          <Box>
                            Boneless
                          </Box>
                          <Box>
                            ₹300
                          </Box>
                        </Box>
                      }

                      <Box sx={{display:'flex', justifyContent:'space-between', fontSize:'11px', marginTop:'5px', opacity:'0.5'}}>
                        <Box>
                          {props.itemDetails[item].extras?.skinType == 'withskin' ? 'With Skin' : 'Skinless'}
                        </Box>
                        <Box>
                          ₹{props.itemDetails[item].extras?.skinType == 'withskin' ? '0' : (props.itemDetails[item].id == 'C067' ? 0 : '100')}
                        </Box>
                      </Box>

                      <Box sx={{display:'flex', justifyContent:'space-between', fontSize:'11px', marginTop:'5px', opacity:'0.5'}}>
                        <Box>
                          Total
                        </Box>
                        <Box>
                          ₹{
                            props.itemDetails[item].price 
                          }
                        </Box>
                      </Box>

                    </AccordionDetails>
                  </Accordion>
                  </>:  null
                  } */}
                </Box>
              </Box>
            )}
          </Box>
        )
      })}

      {orderPageFlag && (
        <Box>
          <Box
            sx={{
              margin: "0 10px 20px 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={textStyle}>Total Price</Box>
              <Box sx={textStyle}>₹{totalPrice}</Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={textStyle}>Delivery Charges</Box>
              <Box sx={textStyle}>₹{deliveryCharges}</Box>
            </Box>
            {/* 
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={textStyle}>COD Handling Charges</Box>
          <Box sx={textStyle}>₹20</Box>
        </Box> */}
          </Box>
          <Divider
            sx={{
              borderStyle: "dotted",
              borderColor: "#a4243d",
              borderWidth: "1px",
              margin: "6px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              lineHeight: "23px",
              fontSize: "18px",
              fontFamily: "RobotoSlab",
              fontWeight: "500",
              marginLeft: "18px",
              marginRight: "15px",
              marginBottom:'10px'
            }}
          >
            <Box>Total</Box>
            <Box>₹{totalPrice + deliveryCharges}</Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default ItemsSummary
